import Masonry from 'masonry-layout';

export default {
    when() {
        return document.querySelectorAll('.flex-masonry').length > 0;
    },

    mounted() {
        // https://github.com/gilbitron/flexmasonry/
        var imagesLoaded = require('imagesloaded');

        let grid = document.querySelector('.flex-masonry');
        let numberOfPosts = 5;
        let allPublications = [];
        let morePublications = [];
        let buttonSeeMore = document.querySelector('#button-see-more');
        let seeMoreSection = document.querySelector('#see-more-section');
        var msnry = new Masonry(grid, {
            itemSelector: '.flexmasonry-item',
            columnWidth: '.grid-sizer',
        });

        // fetch the facebook publications with wordpress rest api
        let page_id = document.querySelector('#pageid')?.innerHTML;

        fetch(`${window.location.origin}/wp-json/wp/v2/pages/${page_id}/?_fields=acf.body
        `)
            .then((response) => response.json())
            .then((data) => {
                let datafiltered = data.acf.body.filter(
                    (layout) => layout.acf_fc_layout == 'section_facebook'
                )[0].publications;

                let promises = datafiltered.map((element) => {
                    return fetch(
                        `${window.location.origin}/wp-json/wp/v2/media/${element.image}`
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            allPublications.push({
                                url: element.url,
                                image: data.source_url,
                            });
                        })
                        .catch((error) => {
                            console.log('errorFetchPublications', error);
                        });
                });

                Promise.all(promises).then(() => {
                    let fragment = document.createDocumentFragment();

                    let allElement = [];
                    allPublications.slice(0, 5).forEach((publication, i) => {
                        let element;
                        let innerNode;
                        let image;
                        element = document.createElement('div');
                        element.classList.add(
                            'flexmasonry-item',
                            'grid-sizer',
                            'p-4'
                        );
                        element.style.width = window.innerWidth > 768 ? '50%' : '100%';
                        innerNode = document.createElement('a');
                        innerNode.classList.add('rounded-[10px]');
                        innerNode.href = publication.url;
                        innerNode.target = '_blank';

                        image = document.createElement('img');
                        image.classList.add(
                            'w-full',
                            'rounded-[10px]',
                            'object-contain',
                            'object-center'
                        );
                        image.src = publication.image;
                        innerNode.appendChild(image);
                        element.appendChild(innerNode);

                        fragment.appendChild(element);
                        allElement.push(element);
                    });
                    grid.appendChild(fragment);

                    msnry.appended(allElement);
                    var imageLoad = imagesLoaded(grid);
                    imageLoad.on('progress', function () {
                        msnry.layout();
                        document.querySelector(
                            '#loading-see-more'
                        ).style.display = 'none';
                        if (allPublications.length > numberOfPosts) {
                            buttonSeeMore.classList.add('block');
                            buttonSeeMore.classList.remove('hidden');
                        } else {
                            seeMoreSection.classList.add('hidden');
                            seeMoreSection.classList.remove('flex');
                        }
                    });
                });
            });

        document
            .querySelector('#button-see-more')
            ?.addEventListener('click', () => {
                let fragment = document.createDocumentFragment();
                morePublications = allPublications.slice(
                    numberOfPosts,
                    numberOfPosts + 5
                );
                let allElement = [];
                morePublications.forEach((publication, i) => {
                    let element;
                    let innerNode;
                    let image;
                    element = document.createElement('div');
                    element.classList.add(
                        'flexmasonry-item',
                        'grid-sizer',
                        'p-4'
                    );
                    element.style.width = window.innerWidth > 768 ? '50%' : '100%';
                    innerNode = document.createElement('a');
                    innerNode.classList.add('rounded-[10px]');
                    innerNode.href = publication.url;
                    innerNode.target = '_blank';

                    image = document.createElement('img');
                    image.classList.add(
                        'w-full',
                        'rounded-[10px]',
                        'object-contain',
                        'object-center'
                    );
                    image.src = publication.image;
                    innerNode.appendChild(image);
                    element.appendChild(innerNode);
                    fragment.appendChild(element);
                    allElement.push(element);
                });
                grid.appendChild(fragment);
                msnry.appended(allElement);
                var imageLoad = imagesLoaded(grid);
                imageLoad.on('progress', function () {
                    msnry.layout();
                    if (allPublications.length <= numberOfPosts) {
                        buttonSeeMore.classList.add('hidden');
                        buttonSeeMore.classList.remove('block');
                        seeMoreSection.classList.add('hidden');
                        seeMoreSection.classList.remove('flex');
                    }
                    document.querySelector('#loading-see-more').style.display =
                        'none';
                });
                numberOfPosts += 5;
            });
    },
};
