import simpleParallax from 'simple-parallax-js';

export default {
    when() {
        return document.querySelectorAll('.parallax').length > 0;
    },

    mounted() {
        setTimeout(() => {
            let parallaxImagesTop = document.querySelectorAll('.parallax-top');
            new simpleParallax(parallaxImagesTop, {
                scale: 1.15,
                transition: 'cubic-bezier(0,0,0,1)',
            });

            let parallaxImagesBottom =
                document.querySelectorAll('.parallax-bottom');
            new simpleParallax(parallaxImagesBottom, {
                scale: 1.15,
                transition: 'cubic-bezier(0,0,0,1)',
            });

            let parallaxImagesRight =
                document.querySelectorAll('.parallax-right');
            new simpleParallax(parallaxImagesRight, {
                scale: 1.15,
                transition: 'cubic-bezier(0,0,0,1)',
                orientation: 'right',
            });
            let parallaxImagesLeft = document.querySelectorAll('.parallax-left');
            new simpleParallax(parallaxImagesLeft, {
                scale: 1.15,
                transition: 'cubic-bezier(0,0,0,1)',
                orientation: 'left',
            });
            let parallaxFullImage =
                document.querySelectorAll('.parallax-full-image');
            new simpleParallax(parallaxFullImage, {
                scale: 1.05,
                transition: 'cubic-bezier(0,0,0,1)',
                orientation: 'up',
            });
            let parallaxGrid =
                document.querySelectorAll('.parallax-grid');
            new simpleParallax(parallaxGrid, {
                scale: 1.25,
                transition: 'cubic-bezier(0,0,0,1)',
                orientation: 'down',
            });
        }, 800);
    },
};
