export default {
    when() {
        return (
            document.querySelectorAll('.custom-buttons-change-logos ').length >
            0
        );
    },

    mounted() {
        let buttons = document.querySelector(
            '.custom-buttons-change-logos '
        ).children;

        let contentCards = null;
        Array.from(buttons).forEach((button, index) => {
            button.addEventListener('click', () => {
                Array.from(buttons).forEach((btn) => {
                   
                    btn.style.transform = btn.style.transform.replace(/scale\([0-9|\.]*\)/, 'scale(' + 1 +')');
                });
                button.style.transform = button.style.transform.replace(/scale\([0-9|\.]*\)/, 'scale(' + 1.2 +')');

         
                contentCards = button.parentElement.parentElement.children[0].children;
                Array.from(contentCards).forEach((card) => {
                    card.classList.remove('opacity-1');
                    card.classList.add('opacity-0');
                })
                contentCards[index].classList.remove('opacity-0');
                contentCards[index].classList.add('opacity-1');
            });
        });
    },
};
