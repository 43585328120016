/**
 * External Dependencies
 */
import Controller from './helpers/Controller';

import common from './controllers/common';
import videoHome from './components/videoHome';
import sliderCard from './components/sliderCard';
import headerNavigation from './components/headerNavigation';
import cardsTransitions from './components/cardsTransitions';
import imageHome from './components/imageHome';
import buttonsColorPicker from './components/buttonsColorPicker';
import buttonsChangeText from './components/buttonsChangeText';
import counterStatistics from './components/counterStatistics';
import slider3D from './components/slider3D';
import Accordion from './components/Accordion';
import FlexMasonry from './components/FlexMasonry';
import sliderFullImages from './components/sliderFullImages';
import dropdownhover from './components/dropdownhover';
import AccordionNav from './components/AccordionNav';
import Modal from './components/Modal';
import sliderAccessories from './components/sliderAccessories';
import Map from './components/Map';
import simpleParallax from './components/simpleParallax';
import GsapSliderScroll from './components/GsapSliderScroll';
import animateScroll from './components/animateScroll';
import aosInit from './components/aosInit';
import animatedItem from './components/animatedItem';
import ModalResellers from './components/ModalResellers';
import ModalUserManuals from './components/ModalUserManuals';

const modules = [
    common,
    videoHome,
    sliderCard,
    headerNavigation,
    cardsTransitions,
    imageHome,
    buttonsColorPicker,
    buttonsChangeText,
    counterStatistics,
    slider3D,
    Accordion,
    sliderFullImages,
    dropdownhover,
    AccordionNav,
    Modal,
    ModalUserManuals,
    sliderAccessories,
    Map,
    GsapSliderScroll,
    animateScroll,
    FlexMasonry,
    simpleParallax,
    aosInit,
    animatedItem,
    ModalResellers
];
const controller = new Controller(modules);
controller.ready();

// @note - this will popup a confirmation before leaving the page
// window.onbeforeunload = () => controller.destroy();
