export default {
    when() {
        return document.querySelectorAll('.modal-button').length > 0;
    },

    mounted() {
        let sections = document.querySelectorAll(
            '.section-image-logo-content-button'
        );
        let sectionsMap = document.querySelectorAll('.section-map');
        let allPage = document.querySelector('body');
        sections.forEach((section) => {
            let button = section.querySelector('.modal-button');
            let closeButton = section.querySelector('.close-modal-button');
            let modal = section.querySelector('.modal');
            let insideModal = section.querySelector('.inside-modal');
            let isOpen = false;
            document.addEventListener(
                'click',
                (event) => {
                    let isInButton = false;
                    if (isOpen == false) {
                        isInButton = event.composedPath().includes(button);
                        if (isInButton) {
                            modal?.classList.add('flex');
                            modal?.classList.remove('hidden');
                            allPage?.classList.add('overflow-hidden');
                            isOpen = true;
                        }
                    } else {
                        let withinBoundaries = event
                            .composedPath()
                            .includes(insideModal);
                        let isCloseButton = event
                            .composedPath()
                            .includes(closeButton);

                        if (
                            withinBoundaries == false ||
                            isCloseButton == true
                        ) {
                            modal?.classList.add('hidden');
                            modal?.classList.remove('flex');
                            allPage?.classList.remove('overflow-hidden');
                            isOpen = false;
                        }
                    }
                },
                { passive: true }
            );
        });
    },
};
