// core version + navigation, pagination modules:
import Swiper, {
    Navigation,

} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export default {
    when() {
        return document.querySelectorAll('.slider-full-images').length > 0;
    },

    mounted() {
        // Header Slider
        // let allSlider = document.querySelectorAll('.slider-full-images');
        let sliderInitImage = [];
        // allSlider.forEach((slider, index) => {
        //     slider
        //         .querySelector('.swiper-header-image')
        //         ?.classList.add(`swiper-header-image-${index}`);
        //     slider
        //         .querySelector('..swiper-images-button-next')
        //         ?.classList.add(`.swiper-images-button-next-${index}`);
        //     slider
        //         .querySelector('..swiper-images-button-prev')
        //         ?.classList.add(`.swiper-images-button-prev-${index}`);
        // });
        // allSlider.forEach((slider, index) => {
            // sliderInitImage.push(
               new Swiper(`.swiper-full-images`, {
                    // configure Swiper to use modules
                    modules: [Navigation],
                    loop: false,
                    allowTouchMove: true,
                    navigation: {
                        nextEl: '.swiper-images-button-next',
                        prevEl: '.swiper-images-button-prev',
                    },
                    slidesPerView: 1.3,
                    spaceBetween: 32,
                })
            // );
        // });
    },
};
