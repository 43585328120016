export default {
    when() {
        return document.querySelectorAll('.accordion-button').length > 0;
    },

    mounted() {
        let accordionButtons = document.querySelectorAll('.accordion-button');
        accordionButtons.forEach((button, index) => {
            let accordionContent =
                    button.parentElement.querySelector('.accordion-content');
                let accordionContentHeight = accordionContent.scrollHeight;
                let buttonIcon = button.querySelector('.fa-solid');
            if (index == 0) {
                button.classList.add('active');
                accordionContent.style.maxHeight =
                    accordionContentHeight + 'px';
                buttonIcon.classList.remove('fa-plus-large');
                buttonIcon.classList.add('fa-minus');
            }
        });
        accordionButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                let accordionContent =
                    button.parentElement.querySelector('.accordion-content');
                let accordionContentHeight = accordionContent.scrollHeight;
                let buttonIcon = button.querySelector('.fa-solid');
                if (button.classList.contains('active')) {
                    accordionContent.style.maxHeight = '0px';
                    button.classList.remove('active');
                    buttonIcon.classList.remove('fa-minus');
                    buttonIcon.classList.add('fa-plus-large');
                } else {
                    button.classList.add('active');
                    accordionContent.style.maxHeight =
                        accordionContentHeight + 'px';
                    buttonIcon.classList.remove('fa-plus-large');
                    buttonIcon.classList.add('fa-minus');
                }
            });
        });
    },
};
