import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import StoreLocatorContext from './StoreLocatorContext';
import StoreLocatorItem from './StoreLocatorItem';
import StoreLocatorSearch from './StoreLocatorSearch';
import { useWindowSize } from './useWindowDimensions';
// @ts-ignore
const StoreLocatorSidebar = React.memo(
    React.forwardRef((props, ref) => {
        return (
            <div className="storelocator-sidebar bg-gray-300 overflow-y-scroll" ref={ref}>
                {props.children}
            </div>
        );
    })
);

StoreLocatorSidebar.displayName = 'CherryStoreLocatorList';

/**
 * Default StoreLocatorSidebar
 */
export default withCustomSidebarComponent({
    item: StoreLocatorItem,
    search: StoreLocatorSearch,
});


export function withCustomSidebarComponent({
    item: CustomItemComponent = null,
    search: CustomSearchComponent = null,
    sidebar: CustomSidebarComponent = null,
}) {
    return (props) => {

        const context = useContext(StoreLocatorContext);
        useEffect(() => {
            let sections = document.querySelectorAll('.section-map');
            let allPage = document.querySelector('body');
            sections.forEach((section) => {
                let button = section.querySelector('.modal-button-resellers');
                let closeButton = section.querySelector('.close-modal-button-resellers');
                let modal = section.querySelector('.modal-resellers');
                let insideModal = section.querySelector('.inside-modal-resellers');
                let isOpen = false;
                document.addEventListener(
                    'click',
                    (event) => {
                        let isInButton = false;
                        if (isOpen == false) {
                            isInButton = event.composedPath().includes(button);
                            if (isInButton) {
                                modal?.classList.add('flex');
                                modal?.classList.remove('hidden');
                                allPage?.classList.add('overflow-hidden');
                                isOpen = true;
                            }
                        } else {
                            let withinBoundaries = event
                                .composedPath()
                                .includes(insideModal);
                            let isCloseButton = event
                                .composedPath()
                                .includes(closeButton);

                            if (
                                withinBoundaries == false ||
                                isCloseButton == true
                            ) {
                                modal?.classList.add('hidden');
                                modal?.classList.remove('flex');
                                allPage?.classList.remove('overflow-hidden');
                                isOpen = false;
                            }
                        }
                    },
                    { passive: true }
                );
            });
        }, []);

        if (CustomSidebarComponent) {
            return <CustomSidebarComponent {...props} context={context} />;
        }

        const refSearch = useRef(null);
        const refSidebar = useRef(null);
        const [listHeight, setListHeight] = useState('auto');
        const size = useWindowSize();
        const onResize = () => {
            if (refSidebar.current && refSearch.current) {
                setListHeight('auto');
                setListHeight(
                    `${
                        refSidebar.current.clientHeight -
                        refSearch.current.clientHeight
                    }px`
                );
            }
        };

        const getGeoLocation = (e) => {
            const beforeLocalisationButtonText = context.acfFields.your_localisation_button;
            context.setAcfFields({
                ...context.acfFields,
                your_localisation_button : context.acfFields.loading_text_wait_for_localisation
            })
            context.acfFields.your_localisation_button
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    };
                    context.setCenter(pos);
                    context.setZoom(12);
                    context.setAcfFields({
                        ...context.acfFields,
                        your_localisation_button : beforeLocalisationButtonText
                    })
                  },
                );
              } else {
                // Browser doesn't support Geolocation
                context.setAcfFields({
                    ...context.acfFields,
                    your_localisation_button : beforeLocalisationButtonText
                })
              }
          
        }

       

        useLayoutEffect(() => {
            window.addEventListener('resize', onResize);
            setTimeout(() => onResize(), 100);
        }, [context.displayedStores]);
        return (
            <StoreLocatorSidebar {...props} ref={refSidebar}>
                <div className="py-10">
                    <div onClick={getGeoLocation} className="custom-map-control-button cursor-pointer mx-4 h-14 px-6 text-gray-900 txt-base rounded-[10px] bg-white flex items-center">
                    <i className="fa-thin fa-location-dot"></i><p className="ml-3 button-loading">{ context.acfFields.your_localisation_button }</p>
                    </div>
                </div>
                <div className="border-y border-y-white py-6">
                    <h1 className="mx-4 h3">{context.acfFields.title}</h1>

                    <CustomSearchComponent
                        {...props}
                        context={context}
                        ref={refSearch}
                    />
                    <p className="mx-4 label-base">
                        {context.acfFields.text_no_resellers}
                    </p>
                    <button className="mx-4 modal-button-resellers mt-2 inline-block mb-4 button-mimas button-base relative py-3.5 px-6 rounded-full text-white bg-gray-700 before:bg-primary-500 max-w-max overflow-hidden">
                        <span>{context.acfFields.button}</span>
                    </button>
                </div>
                {size.width > 767 && (
                    <div
                        className="sidebar-list"
                        style={{ height: listHeight }}
                    >
                        <ul>
                            {context.displayedStores.map((store) => (
                                <li key={store.uid}>
                                    <CustomItemComponent
                                        context={context}
                                        store={store}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </StoreLocatorSidebar>
        );
    };
}
