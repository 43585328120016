import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export default {
    when() {
        return document.querySelectorAll('#video-home').length > 0;
    },

    mounted() {
        let videoHome = document.querySelector('#video-home');
        if (document.querySelector('.video-relative')) {
            let button = document.querySelector('.video-button');
            function playPause() {
                if (videoHome.paused) {
                    videoHome.play();
                    videoHome.muted = false;
                    button.classList.add('opacity-0');
                } else {
                    videoHome.pause();
                    button.classList.remove('opacity-0');
                }
            }

            videoHome.addEventListener('click', () => {
                playPause();
            });
            button.addEventListener('click', () => {
                playPause();
            });
        }

        gsap.registerPlugin(ScrollTrigger);
        gsap.fromTo(
            '#video-home',
            {
                transform: 'translateY: (-200px)',
                width: '50%',
                maxHeight: '100vh',
            },
            {
                scrollTrigger: {
                    trigger: '#video-home',
                    toggleActions: 'restart pause reverse reverse',
                    scrub: true,
                    start: 'top 60%',
                    end: 'center 40%',
                },
                transform: 'translateY(0px)',
                width: '100%',
                maxHeight: '100vh',
                borderRadius: '0px',
            }
        );

        document.addEventListener('DOMContentLoaded', function () {
            var lazyVideos = [].slice.call(
                document.querySelectorAll('video.lazy')
            );

            if ('IntersectionObserver' in window) {
                var lazyVideoObserver = new IntersectionObserver(function (
                    entries,
                    observer
                ) {
                    entries.forEach(function (video) {
                        if (video.isIntersecting) {
                            for (var source in video.target.children) {
                                var videoSource = video.target.children[source];
                                if (
                                    typeof videoSource.tagName === 'string' &&
                                    videoSource.tagName === 'SOURCE'
                                ) {
                                    videoSource.src = videoSource.dataset.src;
                                }
                            }

                            video.target.load();
                            video.target.classList.remove('lazy');
                            lazyVideoObserver.unobserve(video.target);
                        }
                    });
                });

                lazyVideos.forEach(function (lazyVideo) {
                    lazyVideoObserver.observe(lazyVideo);
                });
            }
        });
    },
};
