import React from 'react';

const StoreLocatorContext = React.createContext({
    apiOptions: null,
    setApiOptions(options) {},

    activeStore: null,
    setActiveStore(store) {},

    center: null,
    setCenter(center) {},

    circleOptions: null,
    setCircleOptions(options) {},

    // Set to false to deactivate clusters
    // @see https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js
    clustersOptions: false,
    setClustersOptions(options) {},
    /*clustersOptions: {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        ignoreHidden: true,
        maxZoom: 10,
        styles: null,
    },*/

    displayedStores: [],
    setDisplayedStores(stores) {},

    doActiveMarker(store, openModal = false) {},
    doApplyFilters() {},
    doHighlightMarker(store, radius = 3000) {},

    filters: [],
    setFilters(filters) {},

    geocode: true,
    setGeocode(geocode) {},

    googleMapsApiKey: null,
    setGoogleMapsApiKey(key) {},

    highlightedStore: null,
    setHighlightedStore(uid) {},

    isModalVisible: false,
    setIsModalVisible(visible) {},

    loading: true,
    setLoading(loading) {},

    mapOptions: {},
    setMapOptions(options) {},

    // Default stores markers
    markersOptions: {},
    setMarkersOptions(markersOptions) {},

    position: 'right',
    setPosition(position) {},

    // All stores
    stores: [],
    setStores(stores) {},

    zoom: 8,
    setZoom(zoom) {},

    acfFields: null,
    setAcfFields(acfFields) {},
});

StoreLocatorContext.displayName = 'CherryStoreLocatorContext';

export default StoreLocatorContext;
