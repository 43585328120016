export default {
    when() {
        return document.querySelectorAll(".counter-header").length > 0;
    },

    mounted() {
        const counterHeader = document.querySelector(".counter-header");
        let counterOnce = false;
        function countAnimation(target) {
            function animate(obj, initVal, lastVal, duration) {
                let startTime = null;
                let plus = false;
                if (obj.innerHTML.indexOf("+") !== -1) {
                    plus = true;
                }

                //get the current timestamp and assign it to the currentTime variable
                let currentTime = Date.now();

                //pass the current timestamp to the step function
                const step = (currentTime) => {
                    //if the start time is null, assign the current time to startTime
                    if (!startTime) {
                        startTime = currentTime;
                    }

                    //calculate the value to be used in calculating the number to be displayed
                    const progress = Math.min(
                        (currentTime - startTime) / duration,
                        1
                    );

                    //calculate what to be displayed using the value gotten above
                    if (plus) {
                        obj.innerHTML =
                            Math.floor(
                                progress * (lastVal - initVal) + initVal
                            ) + "+";
                    } else {
                        obj.innerHTML = Math.floor(
                            progress * (lastVal - initVal) + initVal
                        );
                    }

                    //checking to make sure the counter does not exceed the last value (lastVal)
                    if (progress < 1) {
                        window.requestAnimationFrame(step);
                    } else {
                        window.cancelAnimationFrame(
                            window.requestAnimationFrame(step)
                        );
                    }
                };

                //start animating
                window.requestAnimationFrame(step);
            }

            animate(target, 0, target.innerHTML, 2000);
        }

        function inViewport(el) {
            var r, html;
            if (!el || 1 !== el.nodeType) {
                return false;
            }
            html = document.documentElement;
            r = el.getBoundingClientRect();

            return (
                !!r &&
                r.bottom >= 0 &&
                r.right >= 0 &&
                r.top <= html.clientHeight &&
                r.left <= html.clientWidth
            );
        }

        setInterval(function () {
            
            if (inViewport(counterHeader) && !counterOnce) {
                document.querySelectorAll(".counter").forEach((element) => {
                    countAnimation(element);
                });
                counterOnce = true;
            }
        }, 100);
    },
};