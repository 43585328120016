import gsap from 'gsap';
import { ScrollTrigger, ScrollToPlugin } from 'gsap/all';
import AOS from 'aos';

export default {
    when() {
        return document.querySelectorAll('.gsap-slider-container').length > 0;
    },

    mounted() {
        gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
        let mm = gsap.matchMedia();
        let containerMain = document.querySelectorAll('.gsap-scroll-container');

        containerMain.forEach((container) => {
            // Pour mobile, la taille est calculée en fonction de la hauteur la plus haute du texte, il faut détecter la largeur de l'écran en js pour la version desktop (+1024px)
            
        let panels = gsap.utils.toArray('.gsap-slider-panels', container);
        let panelsText = gsap.utils.toArray('.gsap-slider-panels-text', container);
        let panelsPoints = gsap.utils.toArray('.gsap-slider-points', container);
        let gsapContainer = container.querySelector('.gsap-slider-container');
        let containerPoints = container.querySelector('.gsap-points');
        let maxHeightValuePanelsText = 0;
            
            window.addEventListener('resize', resizeHandler);
            resizeHandler();
            function resizeHandler() {
                if (window.innerWidth < 1024) {
                    // En version mobile
                    panelsText.forEach((panel, index) => {
                        if (index == 0) {
                            maxHeightValuePanelsText = panel.offsetHeight;
                        } else {
                            if (panel.offsetHeight > maxHeightValuePanelsText) {
                                maxHeightValuePanelsText = panel.offsetHeight;
                            }
                        }
                    });
                    // MaxHeightValuePanelsText est la taille max que prendra le texte, ensuite il faut placer l'image et les points en fonction de cette taille
                    containerPoints.style.top = `${maxHeightValuePanelsText}px`;
                    panels.forEach((panel, index) => {
                        panel.style.top = `${maxHeightValuePanelsText + 25}px`;
                        panel.style.height = `calc(90vh - ${
                            maxHeightValuePanelsText + 25
                        }px)`;
                    });
                } else {
                    // En desktop, on retire les styles pour qu'il prenne les styles tailwind
                    containerPoints.style.top = null;
                    panels.forEach((panel, index) => {
                        panel.style.top = null;
                        panel.style.height = null;
                    });
                }
            }

            mm.add(
                {
                    isMobile: '(max-width: 1024px)',
                    isDesktop: '(min-width: 1024px)',
                },
                (context) => {
                    let { isMobile, isDesktop } = context.conditions;
                    let tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: gsapContainer,
                            start: 'center-=20 center',
                            end: `+=${(panels.length * 100)}%`,
                            // pin: true,
                            scrub: true,
                        },
                    });

                    //   Cliquer sur un point amène au bon moment de la timeline
                    panelsPoints.forEach((point, index) => {
                        point.addEventListener('click', (e) => {
                            let totalHeight = container.offsetHeight;
                            let heightBeforeContainer =
                                container.offsetTop +
                                container.parentElement.offsetTop;

                            let singleSliderHeight =
                                totalHeight / panelsPoints.length;
                            // Scroll jusqu'à l'endroit en px précis où se trouve le slider par rapport à toute la page
                            gsap.to(window, {
                                duration: 1,
                                scrollTo:
                                    heightBeforeContainer +
                                    index * singleSliderHeight,
                            });
                        });
                    });
                    panels.forEach((panel, i) => {
                        
                        if (i === 0) return;

                        tl.add('start').from(
                            panel,
                            {
                                yPercent: 100,
                                ease: 'none',
                            },
                            'start'
                        );
                        tl.to(
                            panels[i - 1],
                            {
                                yPercent: 0,
                                ease: 'none',
                            },
                            '>'
                        );

                        tl.to(
                            panelsPoints[i],
                            {
                                opacity: 1,
                                ease: 'ease',
                            },
                            'start'
                        );
                        tl.to(
                            panelsPoints[i - 1],
                            {
                                opacity: 0.2,
                                ease: 'ease',
                            },
                            '>'
                        );

                        tl.from(
                            panelsText[i],
                            {
                                yPercent: 20,
                                opacity: 0,
                                ease: 'sine.inOut',
                            },
                            'start'
                        );

                        tl.to(panelsText[i], {
                            opacity: 1,
                        }, '>');
                        tl.to(
                            panelsText[i - 1],
                            {
                                yPercent: isDesktop ? -50 : -10,
                                opacity: 0,
                                ease: 'sine.inOut',
                            },
                            'start'
                        );
                    });
                }
            );
            AOS.refresh()
        });
    },
};
