import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export default {
    when() {
        return document.querySelectorAll('.image-home').length > 0;
    },

    mounted() {
        
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo('.image-home',  {transform: 'translateY: (-200px)', width: '50%', maxHeight: '100vh'}, {
            scrollTrigger: {
                trigger: '.image-home',
                toggleActions: "restart pause reverse reverse",
                scrub: true,
                start: "top 60%", 
                end: "center 40%",
            },
            transform: 'translateY(0px)',
            width: '100%',
            maxHeight: '90vh',
        })
    },
};
