import React, {useContext, useState} from 'react';
import {StandaloneSearchBox} from '@react-google-maps/api';
import StoreLocatorContext from './StoreLocatorContext';
//import Input from '../Input/Input';
//import '../Input/input.css';

const StoreLocatorSearch = React.memo(React.forwardRef((props, ref) => {
    const context = useContext(StoreLocatorContext);
    // @ts-ignore
    const [autocompleteRef, setAutocompleteRef] = useState(null);

    const getAutocompleteAttributes = () => {
        return {
            onLoad(ref) {
                setAutocompleteRef(ref);
            },
            onPlacesChanged() {
                const places = autocompleteRef.getPlaces();

                if (places.length) {
                    const place = places[0];

                    if (place.geometry) {
                        const lat = place.geometry.location.lat();
                        const lng = place.geometry.location.lng();

                        context.setCenter({ lat, lng });
                        //context.setCircle({ center: { lat, lng } });
                        context.setZoom(12);
                    } else {
                        const autocompleteService = new google.maps.places.AutocompleteService();

                        autocompleteService.getPlacePredictions( {
                            // @TODO - apply setup from context
                            input:  place.name,
                            offset: place.name.length,
                        }, (list, status) => {
                            if (list && list.length) {
                                const placesServices = new window.google.maps.places.PlacesService(autocompleteRef);

                                // @ts-ignore
                                const {reference} = list[0];

                                placesServices.getDetails(
                                    // @ts-ignore
                                    {reference},
                                    ( serviceResult, serviceStatus ) => {
                                        // @ts-ignore
                                        if (serviceStatus === google.maps.GeocoderStatus.OK) {
                                            autocompleteRef.set('place', serviceResult);
                                        }
                                    }
                                );
                            }
                        } );
                    }
                }
            },
        };
    };

    return (
        <div className="sidebar-search px-4" {...props} ref={ref}>
            <StandaloneSearchBox {...getAutocompleteAttributes()}>
                <input
                    className="mt-2 input focus:ring-primary-500 focus:border-primary-500 "
                    placeholder={ context.acfFields.input_placeholder_text }
                    type="text"
                />
            </StandaloneSearchBox>
        </div>
    );
}));

StoreLocatorSearch.displayName = 'CherryStoreLocatorSearch';

export default StoreLocatorSearch;
