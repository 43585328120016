import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
    when() {
        return document.querySelectorAll('body');
    },

    mounted() {
        // @see https://michalsnik.github.io/aos/
        AOS.init({
            // @see https://github.com/michalsnik/aos/issues/161
            // disable: () =>
            //     /bot|googlebot|crawler|spider|robot|crawling/i.test(
            //         navigator.userAgent
            //     ),
            delay: 100,
            duration: 500,
            easing: 'ease-in-out',
            once: true,
        });
        setTimeout(() => {
            window.addEventListener('window', AOS.refresh, false);
        }, 800);
    },
};
