export default {
    when() {
        return document.querySelectorAll('#header-navigation').length > 0;
    },

    mounted() {
        var new_scroll_position = 0;
        var last_scroll_position = 0;
        var headerNavigation = document.querySelector('#header-navigation');
        let headerMobile = document.querySelector('#main-nav-mobile');
        let bannerTop = document.querySelector('#banner-top');

        window.addEventListener(
            'scroll',
            () => {
                last_scroll_position = window.scrollY;
                // Scrolling down
                if (
                    new_scroll_position < last_scroll_position &&
                    last_scroll_position > 80
                ) {
                    headerNavigation.classList.add('-translate-y-24');
                    headerNavigation.classList.remove('translate-y-0');
                    // Scrolling up
                } else {
                    if (last_scroll_position > 80) {
                        headerNavigation.classList.remove('-translate-y-24');
                        headerNavigation.classList.add('translate-y-0');
                    }
                    // Use this condition if the navigation change when the client is top scroll position
                    // else {
                    //     headerNavigation.classList.add('-translate-y-24');
                    //     headerNavigation.classList.remove('translate-y-0');
                    // }
                }

                new_scroll_position = last_scroll_position;
            },
            { passive: true }
        );

        let hamburgerOpen = document.querySelector('#hamburger-open');
        let hamburgerClose = document.querySelector('#hamburger-close');
        hamburgerOpen.addEventListener(
            'click',
            () => {
                hamburgerOpen.classList.toggle('hidden');
                hamburgerClose.classList.toggle('hidden');
                hamburgerClose.classList.toggle('flex');
                headerMobile.classList.toggle('nav-active');
                headerMobile.classList.toggle('animate-slide-in-bck-center');
                document
                    .querySelector('html')
                    .classList.toggle('overflow-y-hidden');
                headerMobile.classList.remove('translate-x-full');
                headerMobile.classList.add('translate-x-0');
                headerMobile.style.height = 'calc(100vh - 96px)';
                headerNavigation?.classList.remove('bg-white');
                headerNavigation?.classList.add('bg-gray-900');
                headerNavigation?.querySelector('.logo-dark')?.classList.remove('opacity-100')
                headerNavigation?.querySelector('.logo-dark')?.classList.add('opacity-0')
                headerNavigation?.querySelector('.logo-white')?.classList.add('opacity-100')
                headerNavigation?.querySelector('.logo-white')?.classList.remove('opacity-0')
                headerNavigation?.querySelector('.container')?.classList.remove('bg-white')
            },
            { passive: true },
            false
        );
        hamburgerClose.addEventListener(
            'click',
            () => {
                hamburgerOpen.classList.toggle('hidden');
                hamburgerClose.classList.toggle('hidden');
                hamburgerClose.classList.toggle('flex');
                headerMobile.classList.toggle('nav-active');
                headerMobile.classList.toggle('animate-slide-in-bck-center');
                document
                    .querySelector('html')
                    .classList.toggle('overflow-y-hidden');
                headerMobile.classList.add('translate-x-full');
                headerMobile.classList.remove('translate-x-0');
                headerNavigation.classList.add('bg-white')
                headerNavigation.classList.remove('bg-gray-900')
                headerNavigation?.querySelector('.logo-dark')?.classList.add('opacity-100')
                headerNavigation?.querySelector('.logo-dark')?.classList.remove('opacity-0')
                headerNavigation?.querySelector('.logo-white')?.classList.remove('opacity-100')
                headerNavigation?.querySelector('.logo-white')?.classList.add('opacity-0')
                headerNavigation?.querySelector('.container')?.classList.add('bg-white')
            },
            { passive: true },
            false
        );
    },
};