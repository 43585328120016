// core version + navigation, pagination modules:
import Swiper, {
    Navigation,
    Controller,
    Autoplay,
    Pagination,
    EffectFade,
    EffectCreative,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export default {
    when() {
        return document.querySelectorAll('.custom-slider-swipe-3d').length > 0;
    },

    mounted() {
        // Header Slider
        let allSlider = document.querySelectorAll('.custom-slider-swipe-3d');
        let sliderInitHeaderImage = [];
        let sliderInitHeader = [];
        allSlider.forEach((slider, index) => {
            slider
                .querySelector('.swiper-header-image')
                ?.classList.add(`swiper-header-image-${index}`);
            slider
                .querySelector('.swiper-header')
                ?.classList.add(`swiper-header-${index}`);
            slider
                .querySelector('.swiper-pagination')
                ?.classList.add(`swiper-pagination-${index}`);
        });
        allSlider.forEach((slider, index) => {
            sliderInitHeaderImage.push(
                new Swiper(`.swiper-header-image-${index}`, {
                    // configure Swiper to use modules
                    modules: [Pagination, Controller, EffectCreative],
                    loop: false,
                   
                    allowTouchMove: true,
                    effect: 'creative',
                    creativeEffect: {
                        perspective: true,
                        prev: {
                            rotate: [10, -40, -10],
                            opacity: 0,
                        },
                        next: {
                            rotate: [-10, 40, 10],
                            opacity: 0,
                        },
                    },
                })
            );

            sliderInitHeader.push(
                new Swiper(`.swiper-header-${index}`, {
                    // configure Swiper to use modules
                    modules: [
                        Navigation,
                        Controller,
                        Autoplay,
                        Pagination,
                        EffectFade,
                    ],
                    // navigation: {
                    //     nextEl: '.swiper-header-button-next',
                    //     prevEl: '.swiper-header-button-prev',
                    // },

                    speed: 1000,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true,
                    },
                    loop: false,
                    
                    allowTouchMove: true,
                    pagination: {
                        el: `.swiper-pagination-${index}`,
                        type: 'bullets',
                        clickable: true,
                        // verticalClass: 'swiper-pagination-vertical',
                    },
                    // autoplay: {
                    //     delay: 8000,
                    //     disableOnInteraction: true,
                    //     pauseOnMouseEnter: true,
                    // },
                })
            );
            sliderInitHeaderImage[index].controller.control = sliderInitHeader[index];
            sliderInitHeader[index].controller.control = sliderInitHeaderImage[index];
        });
    },
};
