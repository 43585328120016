import React, {useContext, useState} from 'react';
import classNames from 'classnames';
import {useDebounceFn} from 'ahooks';
// @see https://react-google-maps-api-docs.netlify.app/
// @see https://github.com/JustFly1984/react-google-maps-api/tree/master/packages/react-google-maps-api
import {
    Circle,
    GoogleMap,
    GoogleMapProps,
    Marker,
    MarkerClusterer,
} from '@react-google-maps/api';
import StoreLocatorContext from './StoreLocatorContext';
import StoreLocatorModal from './StoreLocatorModal';

const StoreLocatorMap = React.memo(({
    height = null,
    renderModal = null,
    ...props
}) => {
    const [map, setMap] = useState(null);
    const [savedBounds, setSaveBounds] = useState(null);
    const context = useContext(StoreLocatorContext);

    const getMarkerAnimation = (isActive) => {
        // if (context.loading) {
        //     return google.maps.Animation.DROP;
        // }

        if (isActive && context.markersOptions.animation) {
            return google.maps.Animation[context.markersOptions.animation] ?? context.markersOptions.animation;
        } else {
            setTimeout(() => {
                return google.maps.Animation[context.markersOptions.animation] ?? context.markersOptions.animation;
            }, 1000)
        }

        return undefined;
    };

    const getMarkerIcon = (isActive, store) => {
        // if (typeof store.icon === 'string') {
        //     return store.icon;
        // }

        // if (store.icon !== undefined) {
        //     return store.icon[isActive ? 'active' : 'inactive'];
        // }

        // if (!context.markersOptions.icon) {
        //     return 'https://www.google.com/mapfiles/marker.png';
        // }

        // if (typeof context.markersOptions.icon === 'string') {
        //     return context.markersOptions.icon;
        // }

        // return context.markersOptions.icon[isActive ? 'active' : 'inactive'];
      
        return context.acfFields.marker_icon;
    };

    const getMapAttributes = () => {
        if (context.geocode && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({coords}) => {
                context.setCenter({
                    lat: coords.latitude,
                    lng: coords.longitude,
                });
            });
        }

        const {run: onBoundsChanged} = useDebounceFn(() => {
            if (!map) {
                return;
            }

            const mapBounds = map.getBounds();

            if (JSON.stringify(mapBounds) !== JSON.stringify(savedBounds)) {
                setSaveBounds(mapBounds);
                context.doApplyFilters();
            }
        }, {wait: 200});

        const {run: onCenterChanged} = useDebounceFn(() => {
            if (map) {
                // track center changed by user
                const latLng = map.getCenter();
                const mapCenter = {
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                };
                if (JSON.stringify(context.center) !== JSON.stringify(mapCenter)) {
                    context.setCenter(mapCenter);
                }
            }
        }, {wait: 100});

        const {run: onZoomChanged} = useDebounceFn(() => {
            if (map) {
                // track zoom changed by user
                const mapZoom = map.getZoom();
                if (context.zoom !== mapZoom) {
                    context.setZoom(mapZoom);
                }
            }
            // @note - waiting just a bit to allow scrollwheel zoom
        }, {wait: 10});

        return {
            // @ts-ignore
            center: context.center,
            mapContainerClassName: 'h-full w-full',
            mapContainerStyle: {height},
            onBoundsChanged,
            onCenterChanged,
            onClick() {
                if (context.activeStore) {
                    context.doActiveMarker(null);
                }
            },
            onLoad(map) {
                setMap(map);

                context.setFilters((stores) => {
                    const mapBounds = map.getBounds();
                    // @ts-ignore
                    const centerLatLng = new google.maps.LatLng(context.center.lat, context.center.lng);
                    return stores
                        // add distance between map center and store
                        .map((store) => {
                            const storeLatLng = new google.maps.LatLng(store.lat, store.lng);
                            store.distance = google.maps.geometry.spherical.computeDistanceBetween(centerLatLng, storeLatLng);
                            return store;
                        })
                        // keep only stores inside visible part of the map
                        .filter((store) => {
                            if (!store.radius) {
                                return mapBounds.contains(store);
                            }

                            return mapBounds.contains(store) || store.distance < store.radius;
                        })
                        // order closest first
                        .sort((a, z) => a.distance - z.distance);
                });

                if (context.activeStore) {
                    context.doActiveMarker(context.activeStore, true);
                }//setTimeout(() => context.setLoading(false), 200);
            },
            onZoomChanged,
            options: {
                ...context.mapOptions,
                // @ts-ignore
                center: context.center,
                zoom: context.zoom,
            },
            zoom: context.zoom,
        };
    };

    const getMarkerAttributes = (store) => {
        const isActive = context.activeStore && store.uid === context.activeStore.uid;

        return {
            // @ts-ignore
            animation: getMarkerAnimation(isActive),
            icon: isNaN(context.acfFields.marker_icon) ? context.acfFields.marker_icon : 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.6.0/images/marker-icon.png',
            key: store.uid,
            onClick() {
                const isActive = context.activeStore && store.uid === context.activeStore.uid && context.isModalVisible;
                if (isActive) {
                    context.doActiveMarker(null);
                } else {
                    context.doActiveMarker(store, true);
                }
            },
            onMouseOut() {
                if (!context.activeStore) {
                    context.doHighlightMarker(null);
                }
            },
            onMouseOver() {
                if (!context.activeStore) {
                    context.doHighlightMarker(store, 5000);
                }
            },
            // @ts-ignore
            position: { lat: store.lat, lng: store.lng },
            title: store.name,
        };
    };

    const getModalAttributes = (store) => {
        return {
            context,
            store,
            onClose() {
                context.setIsModalVisible(false);
            },
            visible: context.isModalVisible,
        };
    };

    const renderCircle = (options) => {
        if (options) {
            const circleOptions = {
                fillColor: '#f5a68c',
                fillOpacity: .15,
                radius: 3000,
                strokeColor: '#f5a68c',
                strokeOpacity: .4,
                strokeWeight: 2,
                ...options,
            };

            const circleAttributes = {
                // @ts-ignore
                center: circleOptions.center,
                onClick() {
                    context.doActiveMarker(null);
                },
                options: circleOptions,
                radius: circleOptions.radius,
            };

            // @ts-ignore
            return <Circle {...circleAttributes} />;
        }

        return null;
    };

    const renderMarkers = (stores) => {
        if (context.clustersOptions) {
            return (
                // @ts-ignore
                <MarkerClusterer options={context.clustersOptions}>
                    {(clusterer) => stores.map((store) => (
                        // @ts-ignore
                        <Marker {...getMarkerAttributes(store)} clusterer={clusterer} />
                    ))}
                </MarkerClusterer>
            );
        }

        return context.displayedStores.map((store) => (
            // @ts-ignore
            <Marker {...getMarkerAttributes(store)} />
        ));
    };

    return (
        <div className={classNames('storelocator-map', {'modal-open': context.isModalVisible})}>
            <GoogleMap {...getMapAttributes()}>
                {renderMarkers(context.displayedStores)}
                {renderCircle(context.circleOptions)}
            </GoogleMap>
            {renderModal(getModalAttributes(context.activeStore))}
        </div>
    );
});

StoreLocatorMap.displayName = 'CherryStoreLocatorMap';

/**
 * Default StoreLocator.
 */
export default withCustomModalComponent(StoreLocatorModal);

/**
 * Render a StoreLocatorMap component with given CustomModalComponent.
 * @param CustomModalComponent
 */
export function withCustomModalComponent(CustomModalComponent) {
    return (props) => {
        const renderModal = (props) => <CustomModalComponent {...props} />;
        return <StoreLocatorMap {...props} renderModal={renderModal} />;
    };
}
