export default {
    when() {
        return document.querySelectorAll(".um-modal-button").length > 0;
    },

    mounted() {
        let modal = document.querySelector("#um-modal");
        let closeButton = document.querySelector(".close-modal-button");
        let insideModal = document.querySelector("#inside-modal");
        let allPage = document.querySelector("body");
        let isOpen = false;
        let modalContent = document.querySelector('#modal-content');
        let navBar = document.querySelector('#header-navigation');
        let buttons = document.querySelectorAll(".um-modal-button");
        document.addEventListener(
            "click",
            (event) => {
                let isInButton = false;
                
                if (isOpen == false) {
                    buttons.forEach(button => {
                        if (event.composedPath().includes(button)) {
                            isInButton = true;
                        }
                    })
                    if (isInButton) {
                        modal.classList.add("flex");
                        modal.classList.remove("hidden");
                        allPage.classList.add("overflow-hidden");
                        isOpen = true;
                        navBar.classList.add('hidden')
                        modalContent.innerHTML = '';
                        modalContent.innerHTML = event.target.parentElement.parentElement.querySelector('.iframe-content') ? event.target.parentElement.parentElement.querySelector('.iframe-content').innerHTML : '';
                    }
                } else {
                    let withinBoundaries = event
                        .composedPath()
                        .includes(insideModal);
                    let isCloseButton = event
                        .composedPath()
                        .includes(closeButton);

                    if (withinBoundaries == false || isCloseButton == true) {
                        modal.classList.add("hidden");
                        modal.classList.remove("flex");
                        allPage.classList.remove("overflow-hidden");
                        isOpen = false;
                        navBar.classList.remove('hidden')
                    }
                }
            },
            { passive: true }
        );

    },
};