import ReactDOM from 'react-dom';
import React from 'react';
import StoreLocator from './StoreLocator/StoreLocator';

export default {
    when() {
        return document.querySelectorAll('.section-map').length > 0;
    },

    mounted() {
        const mapOptions = {
            styles: [
                {
                    featureType: 'administrative',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: '-100',
                        },
                    ],
                },
                {
                    featureType: 'administrative.province',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: -100,
                        },
                        {
                            lightness: 65,
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: -100,
                        },
                        {
                            lightness: '50',
                        },
                        {
                            visibility: 'simplified',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: '-100',
                        },
                    ],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'simplified',
                        },
                    ],
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'all',
                    stylers: [
                        {
                            lightness: '30',
                        },
                    ],
                },
                {
                    featureType: 'road.local',
                    elementType: 'all',
                    stylers: [
                        {
                            lightness: '40',
                        },
                    ],
                },
                {
                    featureType: 'transit',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: -100,
                        },
                        {
                            visibility: 'simplified',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [
                        {
                            hue: '#ffff00',
                        },
                        {
                            lightness: -25,
                        },
                        {
                            saturation: -97,
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'labels',
                    stylers: [
                        {
                            lightness: -25,
                        },
                        {
                            saturation: -100,
                        },
                    ],
                },
            ],
        };
        ReactDOM.render(
            <React.StrictMode>
                <StoreLocator
                    googleMapsApiKey="AIzaSyDZnadKR4gumZvRzAVPBUaKZW4_2v-nMfM"
                    mapOptions={mapOptions}
                   
                />
            </React.StrictMode>,

            document.getElementById('map')
        );
    },
};
