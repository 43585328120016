import config from '../config/app';


export default {
    // beforeCreate(args) {
    //     console.log('[common] -> beforeCreate', args);
    // },
    // beforeDestroy(args) {
    //     console.log('[common] -> beforeDestroy', args);
    // },
    // beforeMount(args) {
    //     console.log('[common] -> beforeMount', args);
    // },
    // created(args) {
    //     console.log('[common] -> created', args);
    // },
    // destroyed(args) {
    //     console.log('[common] -> destroyed', args);
    // },
    mounted(args) {
        console.log('[common] -> mounted', args, config.all());

        
       
    },
};
