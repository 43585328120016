export default {
    when() {
        return document.querySelectorAll(".dropdown-button-hover").length > 0;
    },

    mounted() {
        let buttons = document.querySelectorAll(".dropdown-button-hover");

        document.addEventListener(
            "mouseover",
            (event) => {
                buttons.forEach((button) => {
                    let withinBoundaries = event
                        .composedPath()
                        .includes(button.nextElementSibling);
                    let insideButton = event.composedPath().includes(button);
                    if (withinBoundaries || insideButton) {
                        button.nextElementSibling.classList.remove("-translate-y-full");
                        button.nextElementSibling.classList.add("translate-y-0");

                    } else {
                        button.nextElementSibling.classList.remove("translate-y-0"); 
                        button.nextElementSibling.classList.add("-translate-y-full"); 
                    }
                });
            },
            { passive: true }
        );
    },
};