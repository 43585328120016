export default {
    when() {
        return document.querySelectorAll(".accordion-button-nav").length > 0;
    },

    mounted() {
        let accordionButtons = document.querySelectorAll(".accordion-button-nav");

        accordionButtons.forEach((button) => {
            button.addEventListener("click", (e) => {
                let accordionContent =
                    button.parentElement.querySelector(
                        ".accordion-content"
                    );
                let accordionContentHeight = accordionContent.scrollHeight;
                let buttonIcon = button.querySelector(".accordion-button-icon");
                if (button.classList.contains("active")) {
                    accordionContent.style.maxHeight = "0px";
                    button.classList.remove("active");
                    buttonIcon.classList.remove("rotate-180");
                    buttonIcon.classList.add("rotate-0");
                } else {
                    button.classList.add("active");
                    accordionContent.style.maxHeight =
                        accordionContentHeight + "px";
                    buttonIcon.classList.remove("rotate-0");
                    buttonIcon.classList.add("rotate-180");
                }
            });
        });
    },
};