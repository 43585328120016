import gsap from 'gsap';

export default {
    when() {
        return document.querySelectorAll('.animated-item').length > 0;
    },

    mounted() {
        let allItems = document.querySelectorAll('.animated-item');
        allItems.forEach((item) => {
            const tlCan = gsap.timeline({ repeat: -1 });
            /*Can Animation*/
            tlCan
                //move top left
                .to(item, 3, {
                    y: '-=16',
                    x: '+=11',
                    rotation: '-=5',
                    ease: 'power1.inOut',
                })

                //move down right
                .to(item, 2, {
                    y: '+=16',
                    x: '-=11',
                    rotation: '-=5',
                    ease: 'power1.inOut',
                })

                .to(item, 3, {
                    y: '-=11',
                    rotation: '+=5',
                    ease: 'power1.inOut',
                })

                .to(item, 3, {
                    y: '+=11',
                    rotation: '+=5',
                    ease: 'power1.inOut',
                })

                .to(item, 3, { y: '-=15', ease: 'power1.inOut' })

                .to(item, 3, { y: '+=15', ease: 'power1.inOut' })

                .to(item, 3, { y: '-=10', ease: 'power1.inOut' })

                .to(item, 3, { y: '+=10', ease: 'power1.inOut' })

                .to(item, 2, { y: '-=10', ease: 'power1.inOut' })

                .to(item, 2, { y: '+=10', ease: 'power1.inOut' });

            // tlCan.to(tlCan, 27, { ease: 'power1.inOut' });
        });
    },
};
