// core version + navigation, pagination modules:
import Swiper, {
    Navigation,
    Thumbs,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export default {
    when() {
        return document.querySelectorAll('.swiper-accessories').length > 0;
    },

    mounted() {
        var swiper = new Swiper('.swiper-accessories', {
            spaceBetween: 24,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
        });
        var swiper2 = new Swiper('.swiper-accessories-thumbs', {
            modules: [Thumbs, Navigation],
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper: swiper,
            },
        });
    },
};
