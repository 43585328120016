import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export default {
    when() {
        return document.querySelectorAll('.cards-transition').length > 0;
    },

    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        let allCardsTransitions =
            document.querySelectorAll('.cards-transition');
        allCardsTransitions.forEach((cardTransition) => {
            var panels = gsap.utils.toArray(
                cardTransition.querySelectorAll('.panel-transition')
            );
            panels.pop();

            panels.forEach((panel, i) => {
                let startPosition = null;

                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: panel,
                        start: `bottom+=40% bottom`,
                        end: `bottom center-=25%`,
                        pinSpacing: false,
                        pin: true,
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                });

                if (i == 0) {
                    tl.addLabel('startOpacity').to(
                        panel,
                        { yPercent: -50 },
                        'startOpacity'
                    ).to(panel, { opacity: 0}, 'startOpacity+=30%');
                } else {
                    tl.fromTo(
                        panel,
                        { opacity: 1 },
                        { opacity: 0.5, duration: 0.5 },
                        0.4
                    ).to(panel, { opacity: 0, duration: 0.1 });
                }
            });
        });
    },
};
