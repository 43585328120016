import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import classNames from 'classnames';
import merge from 'lodash/merge';
import { useInViewport } from 'ahooks';
import StoreLocatorContext from './StoreLocatorContext';
import { withCustomModalComponent } from './StoreLocatorMap';
import { withCustomSidebarComponent } from './StoreLocatorSidebar';
import slugify from '../../helpers/slugify';
import './storelocator.css';
import DefaultStoreLocatorItem from './StoreLocatorItem';
import DefaultStoreLocatorModal from './StoreLocatorModal';
import DefaultStoreLocatorSearch from './StoreLocatorSearch';
import { useWindowSize } from './useWindowDimensions';

const StoreLocator = React.memo((props) => {
    // reactivity
    const [apiOptions, setApiOptions] = useState(props.apiOptions);
    const [activeStore, setActiveStore] = useState(props.activeStore);
    const [center, setCenter] = useState(props.center);
    const [circleOptions, setCircleOptions] = useState(props.circleOptions);
    const [clustersOptions, setClustersOptions] = useState(
        props.clustersOptions
    );
    const [displayedStores, setDisplayedStores] = useState([]);
    const [filters, setFilters] = useState([]);
    const [geocode, setGeocode] = useState(props.geocode);
    const [googleMapsApiKey, setGoogleMapsApiKey] = useState(
        props.googleMapsApiKey
    );
    const [highlightedStore, setHighlightedStore] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(props.loading);
    const [mapOptions, setMapOptions] = useState(props.mapOptions);
    const [markersOptions, setMarkersOptions] = useState(props.markersOptions);
    const [position, setPosition] = useState(props.position);
    const [stores, setStores] = useState(props.stores);
    const [zoom, setZoom] = useState(props.zoom);
    const [acfFields, setAcfFields] = useState({});

    // internal helpers
    const doActiveMarker = (store, openModal = false) => {
        if (store) {
            setActiveStore(store);
            setCenter({ lat: store.lat, lng: store.lng });
            setZoom(markersOptions.zoom.active);
            setCircleOptions({
                center: { lat: store.lat, lng: store.lng },
            });
            setHighlightedStore(null);

            if (openModal) {
                context.setIsModalVisible(true);
            }
        } else {
            setZoom(markersOptions.zoom.inactive);
            setIsModalVisible(false);
            setCircleOptions(null);
            setHighlightedStore(null);
            setActiveStore(null);
        }
    };

    const doApplyFilters = (customFilter = null) => {
        let filteredStores = filters.reduce((acc, callback) => {
            return callback(acc);
        }, stores);

        if (customFilter) {
            filteredStores = customFilter(filteredStores);
        }

        setDisplayedStores(filteredStores);
    };

    const doHighlightMarker = (store, radius = 3000) => {
        if (store) {
            setCircleOptions({
                center: { lat: store.lat, lng: store.lng },
                radius,
            });
            setHighlightedStore(store.uid);
        } else {
            setCircleOptions(null);
            setHighlightedStore(null);
        }
    };

    useMemo(() => {
        let activeStoreWithUid = props.activeStore;

        if (typeof activeStoreWithUid === 'string') {
            activeStoreWithUid = stores.find(
                (store) => store.uid === activeStoreWithUid
            );
        }

        if (activeStoreWithUid && !activeStoreWithUid.uid) {
            activeStoreWithUid.uid = slugify(
                `m-${activeStoreWithUid.lat}-${activeStoreWithUid.lng}-${activeStoreWithUid.name}`
            );
        }

        setActiveStore(props.activeStore);
    }, [props.activeStore]);

    useMemo(() => {
        setApiOptions({
            libraries: ['geometry', 'places'],
            ...props.apiOptions,
        });
    }, [props.apiOptions]);

    useMemo(() => {
        let page_id = document.querySelector('#pagemapid')?.innerHTML;
        fetch(`${window.location.origin}/wp-json/wp/v2/pages/${page_id}/?_fields=acf.body&acf_format=standard
            `)
            .then((response) => response.json())
            .then((data) => {
                setAcfFields(data.acf.body[0]);
                setCenter({
                    lat: data.acf.body[0].default_map.lat,
                    lng: data.acf.body[0].default_map.lng,
                });
                setZoom(data.acf.body[0].default_map.zoom);
            });
    }, [props.acfFields]);

    useMemo(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useMemo(() => {
        setMapOptions(props.mapOptions);
    }, [props.mapOptions]);

    useMemo(() => {
        setMarkersOptions(props.markersOptions);
    }, [props.markersOptions]);

    useMemo(() => {
        setPosition(props.position);
    }, [props.position]);

    useMemo(() => {
        setZoom(props.zoom);
    }, [props.zoom]);

    useMemo(() => {
        async function loadData() {
            const response = await fetch(
                '/wp-json/wp/v2/resellers/?_fields=acf'
            );
            if (!response.ok) {
                return;
            }

            const resellers = await response.json();
            const storesTemp = [];
            resellers.map((reseller) => {
                if (reseller.acf.position) {
                    if (reseller.acf.position.lat && reseller.acf.position.lng) {
                        storesTemp.push({
                            lat: reseller.acf.position.lat,
                            lng: reseller.acf.position.lng,
                            name: reseller.acf.name,
                            phone: reseller.acf.phone,
                            website: reseller.acf.website,
                            address: reseller.acf.position.address,
                        });
                    }
                }
            });
            const storesWithUid = storesTemp.map((store) => {
                if (!store.uid) {
                    store.uid = slugify(
                        `m-${store.lat}-${store.lng}-${store.name}`
                    );
                }

                return store;
            });
            setStores(storesTemp);
            setTimeout(() => doApplyFilters(), 100);
        }
        loadData();
    }, [props.stores]);

    const ref = useRef(null);
    const [inViewport] = useInViewport(ref);

    // load Google Maps API
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey,
        ...apiOptions,
    });

    const classes = classNames(
        'storelocator',
        `map-${position}`,
        {
            loading: loading && !isLoaded,
            'not-visible': !inViewport,
        },
        props.className
    );

    useEffect(() => {
        if (isLoaded || loadError) {
            setTimeout(() => setLoading(false), 100);
        }
    }, [isLoaded, loadError]);

    const context = {
        acfFields,
        setAcfFields,
        apiOptions,
        setApiOptions,
        // @ts-ignore
        activeStore,
        setActiveStore,
        center,
        setCenter,
        circleOptions,
        setCircleOptions,
        clustersOptions,
        setClustersOptions,
        displayedStores,
        setDisplayedStores,
        doActiveMarker,
        doApplyFilters,
        doHighlightMarker,
        filters,
        setFilters(filter) {
            setFilters([...filters, filter]);
        },
        geocode,
        setGeocode,
        googleMapsApiKey,
        setGoogleMapsApiKey,
        highlightedStore,
        setHighlightedStore,
        isModalVisible,
        setIsModalVisible,
        loading,
        setLoading,
        mapOptions,
        setMapOptions,
        markersOptions,
        setMarkersOptions,
        position,
        setPosition,
        stores,
        setStores,
        zoom,
        setZoom,
    };

    const render = () => {
        if (loadError) {
            return (
                <div className={classes} style={{ height: props.height }}>
                    Map cannot be loaded right now, sorry.
                </div>
            );
        }

        if (!isLoaded || loading) {
            return props.renderLoader(props);
        }

        return <>{props.children}</>;
    };
    const size = useWindowSize();
    return (
        <div className={classes}>
            <StoreLocatorContext.Provider value={context}>
                <div
                    className="storelocator-wrapper"
                    ref={ref}
                    style={{ height: size.width > 767 && props.height }}
                >
                    {render()}
                </div>
            </StoreLocatorContext.Provider>
        </div>
    );
});

StoreLocator.displayName = 'CherryStoreLocator';

const DefaultLoaderComponent = (props) => <div {...props}>Loading...</div>;

/**
 * Default StoreLocator.
 */
export default withCustomComponents({
    item: DefaultStoreLocatorItem,
    loader: DefaultLoaderComponent,
    modal: DefaultStoreLocatorModal,
    search: DefaultStoreLocatorSearch,
});

const withDefaultProps = (props) => {
    props = merge(
        {
            activeStore: null,
            apiOptions: {
                libraries: ['geometry', 'places'],
            },
            googleMapsApiKey: null,
            center: {
                lat: 50.6740609,
                lng: 4.470022,
            },
            circleOptions: {
                fillColor: '#ff0',
                fillOpacity: 0.15,
                radius: 3000,
                strokeColor: '#f5a68c',
                strokeOpacity: 0.4,
                strokeWeight: 2,
            },
            clustersOptions: null,
            geocode: false,
            height: '765px',
            loading: true,
            loadingMessage: 'Loading...',
            mapOptions: {
                clickableIcons: false,
                fullscreenControl: false,
                gestureHandling: 'cooperative',
                mapTypeControl: false,
                mapTypeControlOptions: false,
                scrollWheel: false,
                streetViewControl: false,
            },
            markersOptions: {
                circle: true,
                animation: 'BOUNCE',
                icon: '',
                zoom: {
                    active: 12,
                    inactive: 8,
                },
            },
            position: 'right',
            stores: [],
            zoom: 8,
        },
        props
    );

    if (!props.renderLoader) {
        props.renderLoader = (props) => <div>{props.loadingMessage}</div>;
    }

    return props;
};

/**
 * Render a StoreLocator with given CustomItemComponent, CustomModalComponent and CustomSearchComponent.
 */
export function withCustomComponents({
    item: CustomItemComponent = DefaultStoreLocatorItem,
    modal: CustomModalComponent = DefaultStoreLocatorModal,
    search: CustomSearchComponent = DefaultStoreLocatorSearch,
    sidebar: CustomSidebarComponent = null,
    loader: CustomLoaderComponent = DefaultLoaderComponent,
}) {
    const FinalStoreLocatorSidebarComponent = withCustomSidebarComponent({
        item: CustomItemComponent,
        search: CustomSearchComponent,
        sidebar: CustomSidebarComponent,
    });
    const FinalStoreLocatorMapComponent =
        withCustomModalComponent(CustomModalComponent);

    return React.memo((props) => {
        const propsWithDefault = withDefaultProps(props);

        let height = propsWithDefault.height;

        if (['bottom', 'top'].includes(propsWithDefault.position)) {
            height = null;
        }

        return (
            <StoreLocator {...propsWithDefault}>
                <Suspense fallback={<CustomLoaderComponent />}>
                    <FinalStoreLocatorMapComponent height={height} />
                    <FinalStoreLocatorSidebarComponent height={height} />
                </Suspense>
            </StoreLocator>
        );
    });
}
