import React from 'react';
import classNames from 'classnames';

const StoreLocatorItem = React.memo((props) => {
    const context = props.context;
    const store = props.store;

    const className = classNames(
        {
            active: store.uid === context.activeStore?.uid,
            hover: store.uid === context.highlightedStore,
        },
        props.className
    );

    const onClick = () => {
        if (store.uid === context.activeStore?.uid) {
            context.doActiveMarker(null);
        } else {
            context.doActiveMarker(store, false);
        }
    };

    const onMouseEnter = () => {
        if (!context.activeStore) {
            context.doHighlightMarker(store, 5000);
        }
    };

    const onMouseLeave = () => {
        if (!context.activeStore) {
            context.doHighlightMarker(null);
        }
    };

    return (
        <div
            className="py-6 cursor-pointer"
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
                <p className="h4">{store.name}</p>
                <p className="pt-2 txt-base">{store.phone}</p>
                <p className="pt-2 txt-base">{store.address}</p>
        </div>
    );
});

StoreLocatorItem.displayName = 'CherryStoreLocatorItem';

export default StoreLocatorItem;
