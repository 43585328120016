export default {
    when() {
        return document.querySelectorAll('.custom-colors-picker').length > 0;
    },

    mounted() {
        let buttons = document.querySelector('.custom-colors-picker').children;
        let images = null;
        Array.from(buttons).forEach((button, index) => {
            button.addEventListener('click', () => {
                Array.from(buttons).forEach((btn) => {
                    btn.firstChild.classList.remove('opacity-1');
                    btn.firstChild.classList.add('opacity-0');
                    

                })
                button.firstChild.classList.remove('opacity-0');
                button.firstChild.classList.add('opacity-1');
                images = button.parentElement.parentElement.parentElement.children[1].children;
                Array.from(images).forEach((image) => {
                    image.classList.remove('opacity-1');
                    image.classList.add('opacity-0');
                })
                images[index].classList.remove('opacity-0');
                images[index].classList.add('opacity-1');
            })
            
        })
    },
};
