import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';


export default {
    when() {
        return document.querySelectorAll('.anim-scroll').length > 0;
    },

    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        let dots = document.querySelectorAll('.dbg .dot');
        let btn = document.querySelectorAll('.dbg button');

        btn.addEventListener('click', function () {
            dots.classList.classList.remove('trigger').find('> div').classList.remove('trigger');
        });

        let st = ScrollTrigger.batch('.anim-scroll', {
            end: 'bottom top',
            start: 'top bottom',
            onToggle: function (els, st) {
                els.forEach((el) => {
                    let id = parseInt(
                        el.getAttribute('class').split(' ')[2].replace('t', ''),
                        10
                    );
                    dots.eq(id - 1).classList.add('trigger');
                    if (st[0].isActive) {
                        el.classList.add('is-animated');
                    }
                });
            },
            onEnter: function (els, st) {
                els.forEach((el) => {
                    let id = parseInt(
                        el.getAttribute('class').split(' ')[2].replace('t', ''),
                        10
                    );
                    dots.eq(id - 1)
                        .querySelectorAll('.e')
                        .classList.add('trigger');
                });
            },
            onEnterBack: function (els, st) {
                els.forEach((el) => {
                    let id = parseInt(
                        el.getAttribute('class').split(' ')[2].replace('t', ''),
                        10
                    );
                    dots.eq(id - 1)
                        .querySelectorAll('.eb')
                        .classList.add('trigger');
                });
            },
            onLeave: function (els, st) {
                els.forEach((el) => {
                    let id = parseInt(
                        el.getAttribute('class').split(' ')[2].replace('t', ''),
                        10
                    );
                    dots.eq(id - 1)
                    .querySelectorAll('.l')
                        .classList.add('trigger');
                });
            },
            onLeaveBack: function (els, st) {
                els.forEach((el) => {
                    let id = parseInt(
                        el.getAttribute('class').split(' ')[2].replace('t', ''),
                        10
                    );
                    dots.eq(id - 1)
                    .querySelectorAll('.lb')
                        .classList.add('trigger');
                });
            },
        });
    },
};
