/**
 * Slugify a string
 * @param {String} string - String to slugify
 * @returns {string} - Slugified string
 */
export default function slugify(string) {
    string = string.toString().toLowerCase()
        .replace(/^\s+|\s+$/g, '');

    // remove accents, swap ñ for n, etc
    let from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    let to   = "aaaaaeeeeeiiiiooooouuuunc------";

    for ( let i = 0, l = from.length; i < l; i++ ) {
        string = string.replace( new RegExp( from.charAt( i ), 'g'), to.charAt( i ) );
    }

    return string.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes
}
