import React, { useContext } from 'react';
import StoreLocatorContext from './StoreLocatorContext';

const StoreLocatorModal = React.memo((props) => {
    const context = useContext(StoreLocatorContext);
    if (props.visible) {
        return (
            <div className="storelocator-modal">
                <button
                    className="btn btn-light btn-md btn-rounded icon-only close"
                    onClick={props.onClose}
                    type="button"
                >
                    <i
                        className="fa-solid fa-times fa-fw"
                        aria-hidden="true"
                    ></i>
                </button>
                <div className="text-left mx-6 mt-6 md:mt-10 mb-6 ">
                    <h1 className="h2">{props.store.name}</h1>
                    {props.store.phone && (
                        <>
                            <p className="mt-6 h3">
                                {context.acfFields.modal_phone_field}
                            </p>
                            <p className="mt-2 txt-base">{props.store.phone}</p>
                        </>
                    )}
                    {props.store.website && (
                        <>
                            <p className="mt-6 h3">
                                {context.acfFields.modal_website_field}
                            </p>
                            <a
                                href={props.store.website}
                                target="_blank"
                                className="mt-2 txt-base"
                            >
                                {props.store.website}
                            </a>
                        </>
                    )}
                    <p className="mt-6 h3">
                        {context.acfFields.modal_address_field}
                    </p>
                    <p className="mt-2 txt-base ">{props.store.address}</p>
                    <a
                        href={`https://maps.google.com/?q=${props.store.lat},${props.store.lng}
`} target="_blank"
                        className="mt-2 txt-base text-blue-700"
                    >
                        {context.acfFields.modal_link_google_map_text_field}
                    </a>
                </div>
            </div>
        );
    }

    return <div className="storelocator-modal hidden"></div>;
});

StoreLocatorModal.displayName = 'CherryStoreLocatorModal';

export default StoreLocatorModal;
